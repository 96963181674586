import { Icon, Menu, MenuItem } from "@material-ui/core";
import {
    BugReport as BugReportIcon,
    Edit as EditIcon,
    Help as HelpIcon,
    OndemandVideo as OndemandVideoIcon
} from "@material-ui/icons";
import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { Divider } from "js/Components/Divider";
import { buildVersion, isInternalBAIUser } from "js/config";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { openTeamUpgrade } from "js/core/utilities/externalLinks";
import { isShift } from "js/core/utilities/keys";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import Avatar from "js/react/components/Avatar";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import FeedbackDialog from "js/react/components/Dialogs/FeedbackDialog";
import { Gap10, Gap30 } from "js/react/components/Gap";
import { NestedMenuItem } from "js/react/components/NestedMenuItem";
import { BlueButton } from "js/react/components/UiComponents";
import { themeColors } from "js/react/sharedStyles";
import ShortcutsDialog from "js/react/views/PPTAddin/Components/ShortcutsDialog";
import PresentationEditorController, { PanelType } from "../PresentationEditor/PresentationEditorController";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.HELP_MENU);

const MenuSection = styled.div`
    max-width: 250px;
    padding: 10px 20px 14px 14px;
    font-size: 11px;
    line-height: 13px;
    color: #666;

    .title {
        font-size: 13px;
        line-height: 21px;
        font-weight: 600;
        text-transform: uppercase;
        color: #333;
    }
`;

const InfoBlock = styled.div`
    padding: 15px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
    pointer-events: auto;
    user-select: text;
    max-width: 220px;
`;

const Info = styled.div`
    padding: 15px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
    pointer-events: auto;
    user-select: text;
    max-width: 220px;
`;

const BuildVersion = styled.span`
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
`;

async function getAppcuesLaunchpad() {
    const accountId = 84430;
    const user = auth().currentUser;
    const url = `https://api.appcues.com/v1/accounts/${accountId}/users/${user.uid}/taco?url=${window.location}`;
    const res = await fetch(url);
    const data = await res.json();
    logger.info("[Appcues Launchpad]", data);
    return data;
}

export const UserMenu = AppController.withState(class UserMenu extends Component {
    state = {
        anchorEl: null,
        tutorialVideo: [],
        shiftIsPressed: false
    }

    async componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);

        const data = await getAppcuesLaunchpad();
        const items = data.contents
            .sort((a, b) => a.name > b.name ? 1 : -1)
            .map(content => {
                return {
                    label: content.name,
                    value: content.id,
                };
            });

        this.setState({
            tutorialVideo: items
        });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = event => {
        if (isShift(event.which)) {
            this.setState({ shiftIsPressed: true });
        }
    }

    showMenu = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    }

    closeMenu = event => {
        this.setState({
            anchorEl: null
        });
    }

    handleEditProfile = () => {
        AppController.showAccountPane({ pane: "me" });
    }

    handleSwitchWorkspace = workspaceId => {
        this.closeMenu();

        if (window.roomID && window.roomWorkspaceSwitched) {
            window.roomWorkspaceSwitched(workspaceId);
            return;
        }

        AppController.switchWorkspace(workspaceId);
    }

    handleOpenZendesk = () => {
        if (window.zendeskInit) {
            window.zendeskInit();
            window.zE(() => {
                window.zE.activate();
            });
        }

        this.closeMenu();
    }

    handleShowFeedbackDialog = () => {
        ShowDialog(FeedbackDialog);
        this.closeMenu();
    }

    handleShowShortcutsDialog = () => {
        ShowDialog(ShortcutsDialog);
        this.closeMenu();
    }

    handleLogout = () => {
        if (window.roomID && window.roomUserLoggedOut) {
            window.roomUserLoggedOut();
            return;
        }

        trackActivity("Auth", "logout", null, null, { workspace_id: "all" }, { audit: true });
        ds.shutdown();
        window.location = "/logout";
    }

    renderSwitchWorkspaceMenu() {
        const { canSwitchWorkspace, workspaceId, user } = this.props;

        const workspace = user.workspacesMetadata.find(workspace => workspace.id === workspaceId);

        return (
            <NestedMenuItem
                id="menu-item-workspace"
                showNestedOnClick
                disabled={!canSwitchWorkspace}
                contents={
                    <Fragment><Icon>{workspace.id === "personal" ? "person" : "business"}</Icon>Switch Workspace</Fragment>
                }
            >
                {Object.values(user.workspacesMetadata).map(ws => (
                    <MenuItem
                        key={ws.id}
                        value={ws.id}
                        onClick={() => this.handleSwitchWorkspace(ws.id)}
                    >
                        {ws == workspace && <Icon style={{ color: themeColors.ui_blue }}>check</Icon>}
                        {ws != workspace && <Gap30 />}
                        <Icon>{ws.id === "personal" ? "person" : "business"}</Icon>
                        {ws.id === "personal" ? "Personal" : ws.name}
                        <Gap10 />
                    </MenuItem>
                ))}
                {!app.integrator && !window.isZoomApp && <>
                    <hr />
                    <MenuItem key="create" onClick={() => openTeamUpgrade()}>Create Workspace...</MenuItem>
                </>}
            </NestedMenuItem>
        );
    }

    render() {
        const { workspaceId, user } = this.props;
        const { anchorEl, tutorialVideo, shiftIsPressed } = this.state;

        const workspace = user.workspacesMetadata.find(workspace => workspace.id === workspaceId);

        if (window.isZoomApp) {
            return (<>
                <Avatar id="userMenu" onClick={this.showMenu} url={AppController.userAvatarUrl} />
                <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.closeMenu}
                >
                    {this.renderSwitchWorkspaceMenu()}
                    <hr />
                    <MenuItem id="menu-item-logout" onClick={this.handleLogout}>
                        <Icon>logout</Icon>
                        Log out
                    </MenuItem>
                </Menu>
            </>);
        }

        return (<>
            <Avatar id="userMenu" onClick={this.showMenu} url={AppController.userAvatarUrl} />
            {workspace && <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={this.closeMenu}
            >
                <MenuItem id="menu-item-profile" onClick={this.handleEditProfile}><Icon>account_circle</Icon>Manage Account</MenuItem>
                <hr />
                {this.renderSwitchWorkspaceMenu()}
                <hr />
                <NestedMenuItem showNestedOnClick contents={
                    <Fragment><HelpIcon />Help</Fragment>
                }>
                    <MenuItem onClick={this.handleOpenZendesk}>
                        <Icon>support</Icon>
                        <span>Get Help</span>
                    </MenuItem>
                    <NestedMenuItem label="Tutorials" icon={<OndemandVideoIcon />}>
                        {PresentationEditorController.isSlideGridVisible() ? (
                            <InfoBlock>
                                <div style={{ textAlign: "center" }}>
                                    Tutorials are not currently designed
                                    <br />
                                    to work with the Organize View.
                                    <br />
                                    Please return to the Editor View and try again.
                                </div>
                            </InfoBlock>
                        ) : tutorialVideo.length === 0 ? (
                            <InfoBlock>
                                <div style={{ textAlign: "center" }}>
                                    Nothing to see here.
                                    <br />
                                    Check back later!
                                </div>
                            </InfoBlock>
                        ) : tutorialVideo.map((video, index) => (
                            <MenuItem
                                key={index}
                                value={video.value}
                                onClick={() => {
                                    this.closeMenu();
                                    window.Appcues.show(video.value);
                                    trackActivity("HelpCenter", "PlayTour", null, null, { source: "UserMenu", flowId: video.value }, { audit: false });
                                }}
                            >
                                {video.label}
                            </MenuItem>
                        ))}
                    </NestedMenuItem>
                    <MenuItem onClick={this.handleShowFeedbackDialog}>
                        <EditIcon />
                        <span>Give Feedback</span>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        AppController.showDebugInfo();
                        this.closeMenu();
                    }}>
                        <BugReportIcon />
                        <span>Debug Info</span>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {
                        AppController.setExternalActivePanel(PanelType.SHORTCUTS);
                        this.closeMenu();
                    }}>
                        <span>Keyboard Shortcuts</span>
                        <span style={{ marginLeft: 50 }}>⌘ ?</span>
                    </MenuItem>

                    {shiftIsPressed && <>
                        <Divider />
                        <Info>
                            <BuildVersion>Build {buildVersion}</BuildVersion>
                        </Info>
                    </>}

                    {isInternalBAIUser(AppController.user.getEmail()) && <><Divider />
                        <MenuItem onClick={() => {
                            // Hide all extenal panels, i.e. shortcut panel but keep the other internal panels on
                            // in order to allow user to see the bug report it better
                            AppController.setExternalActivePanel(null);
                            AppController.showInternalBugReport();
                            this.closeMenu();
                        }}>
                            <BugReportIcon />
                            <span>Internal Bug Report</span>
                        </MenuItem></>}
                </NestedMenuItem>
                <hr />
                <MenuItem id="menu-item-logout" onClick={this.handleLogout}><Icon>logout</Icon>Log out</MenuItem>
                {workspaceId === "personal" && <hr />} {/* need two conditions here to bypass MUI Menu warning for having Fragments as child */}
                {workspaceId === "personal" && <MenuSection>
                    <div className="title">Invite your team</div>
                    <div>Keep your team on brand and on track with shared resources and advanced collaboration.</div>
                    <Gap10 />
                    <BlueButton
                        fullWidth
                        onClick={() => openTeamUpgrade({ cta: "UserMenu" })}
                    >Create a workspace</BlueButton>
                </MenuSection>}
            </Menu>}
        </>);
    }
});
