import React, { Component } from "react";
import zoomSdk from "@zoom/appssdk";
import type {
    Apis,
    DecryptedAppContextResponse,
    RunningContext,
    RenderingContextView,
    OnParticipantChangeEvent,
    ConfigResponse,
} from "@zoom/appssdk";
import firebase from "firebase/compat/app";
import _ from "lodash";

import { app } from "js/namespaces";
import { auth } from "js/firebase/auth";
import { serverContext, serverUrl } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import { getPresentation } from "js/core/models/presentation";
import Spinner from "js/react/components/Spinner";
import { ShowConfirmationDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { PresentationPrivacyType } from "common/constants";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { browserHistory } from "js/react/history";
import * as analytics from "js/analytics";
import Api from "js/core/api";

import ZoomAppAuth from "./ZoomAppAuth";
import ZoomAppLibrary from "./ZoomAppLibrary";
import ZoomAppPlayer from "./ZoomAppPlayer";
import ZoomAppPresenter, { CameraOverlayPosition } from "./ZoomAppPresenter";
import ZoomAppMainClient from "./ZoomAppMainClient";

const logger = getLogger(LogGroup.ZOOM);

const ZOOM_CAPABILITIES: Apis[] = [
    // general permissions
    "openUrl",
    "sendAppInvitationToAllParticipants",
    "sendAppInvitation",
    "getUserContext",
    "sendAppToBackground",
    "bringAppToFront",

    // meeting related permissions
    "getMeetingParticipants",
    "onParticipantChange",

    // layers api related permissionss
    "runRenderingContext",
    "closeRenderingContext",
    "drawParticipant",
    "clearParticipant",
    "drawImage",
    "clearImage",
    "drawWebView",
    "clearWebView",
];

declare global {
    interface Window {
        isPlayer: boolean;

        // zoom app related
        isZoomApp: boolean;
        zoomApp: {
            runningContext: RunningContext;
            appContext: DecryptedAppContextResponse;
        };

        // remmote-control channel related
        roomID: string;
        remoteRole: "leader" | "follower";
        roomPresentationStarted: (presentationId: string) => Promise<void>;
        roomPresentationClosed: (presentationId: string) => Promise<void>;
        roomWorkspaceSwitched: (workspaceId: string) => void;
        roomUserLoggedOut: () => Promise<void>;
    }
}

interface ZoomAppProps {
    firebaseUser: firebase.User;
}

interface ZoomAppState {
    loading: boolean;
    loadingError: Error | null;
    configResponse: ConfigResponse | null;
    runningContext: RunningContext | null;
    renderingContext: RenderingContextView | null;
    leaderParticipantId: string | null;
    participantId: string | null;
    participantIds: string[];
    participantsDrawn: string[];
    isStartingToPresent: boolean;
    showPresenterView: boolean;
    presentationId: string | null;
    presentationLinkId: string | null;
    startSlideIndex: number;
    immersiveCameraOverlayPosition: CameraOverlayPosition;
    workspaceId: string;
    privacySettingChanged: string;
}

class ZoomApp extends Component<ZoomAppProps, ZoomAppState> {
    remoteChannel: ExtendedChannel | null = null;
    playerRef: React.RefObject<ZoomAppPlayer> = React.createRef();

    state: ZoomAppState = {
        loading: true,
        loadingError: null,
        configResponse: null,
        runningContext: null,
        renderingContext: null,
        leaderParticipantId: null,
        participantId: null,
        participantIds: [],
        participantsDrawn: [],
        isStartingToPresent: false,
        showPresenterView: false,
        presentationId: null,
        presentationLinkId: null,
        startSlideIndex: 0,
        immersiveCameraOverlayPosition: CameraOverlayPosition.OFF,
        workspaceId: "",
        privacySettingChanged: "",
    };

    get appContext() {
        return serverContext.zoomContext as DecryptedAppContextResponse;
    }

    async componentDidMount() {
        // redirect to library if opened from browser
        if (!serverContext.isZoom) {
            logger.info("[ZoomApp] opened from browser, redirecting to library");
            browserHistory.push("/library");
            return;
        }

        // set constrained view so library can render properly in side panel
        window.document.body.classList.add("is_mobile");
        app.isMobileOrTablet = true;
        app.isConstrained = true;

        // set window field to indicate that the app is running in the ZoomApp environment
        window.isZoomApp = true;

        try {
            // check if user is coming back from the authentication page
            if (this.appContext.act?.startsWith("authSuccess")) {
                const opaqueToken = this.appContext.act.split(":")[1];

                const res = await fetch(`${serverUrl}/zoom/token?opaqueToken=${opaqueToken}`, { method: "GET", headers: { "Content-Type": "application/json" } });
                const { idToken: userIdToken } = await res.json();

                if (auth().currentUser) {
                    await auth().signOut();
                }
                await auth().signInWithCustomToken(userIdToken);
            }

            // check if user still has zoom auth tokens
            if (auth().currentUser) {
                const zoomAuth = await Api.getZoomTokens.get();
                if (!zoomAuth?.hasTokens) {
                    logger.info(`[ZoomApp] user disconnected from zoom, logging out`);
                    await auth().signOut();
                }
            }

            const configResponse = await this.configureSDK();
            logger.info("[ZoomApp] configure sdk done", configResponse);

            const { runningContext } = configResponse;
            this.setState({
                loading: false,
                runningContext,
                configResponse,
            });

            // set window field to pass zoom context to the app
            window.zoomApp = { runningContext, appContext: this.appContext };

            // set analytics state field to notify they come from Zoom App
            analytics.trackState({ isZoomApp: true });
        } catch (err) {
            logger.error(err, "Error configuring the ZoomApps SDK");
            this.setState({ loading: false, loadingError: err });
        }
    }

    componentWillUnmount() {
        logger.info("[ZoomApp] componentWillUnmount()");

        // unsubscribe from remote-control channel
        if (this.remoteChannel) {
            this.remoteChannel.unsubscribe();
        }

        // close rendering context if it's open
        if (this.state.renderingContext) {
            zoomSdk.closeRenderingContext();
        }

        // unset window fields
        window.isZoomApp = false;
        window.zoomApp = null;
        window.roomID = null;
        window.roomPresentationStarted = null;
        window.roomPresentationClosed = null;
        window.roomWorkspaceSwitched = null;
        window.roomUserLoggedOut = null;

        // remove analytics state field for zoom app
        analytics.trackState({ isZoomApp: false });
    }

    configureSDK = async () => {
        logger.info("[ZoomApp] configureSDK() init", { appContext: this.appContext });

        const configResponse = await zoomSdk.config({
            capabilities: ZOOM_CAPABILITIES
        });

        this.addZoomListeners();

        const { runningContext } = configResponse;

        if (["inMeeting", "inImmersive"].includes(runningContext)) {
            logger.info("[ZoomApp] configureSDK() parsing roomID & setting up MeetingRoom listeners");
            await this.setupMeetingRoom(runningContext);
        }

        window.roomWorkspaceSwitched = this.handleWorkspaceSwitch;
        window.roomUserLoggedOut = this.handleUserLogout;

        return configResponse;
    };

    addZoomListeners = () => {
        zoomSdk.onParticipantChange(async (event: OnParticipantChangeEvent) => {
            logger.info(`[ZoomApp] onParticipantChange()`, event);

            const participantsJoined = event.participants.filter(({ status }) => status === "join");
            const participantsLeft = event.participants.filter(({ status }) => status === "leave");

            if (participantsJoined.length) {
                logger.info(`[ZoomApp] onParticipantChange() participants joined`, participantsJoined);

                // get full list of participant ids
                const participantIds = [...this.state.participantIds, ...participantsJoined.map(({ participantUUID }) => participantUUID)]
                    .filter((participantId, index, self) => self.indexOf(participantId) === index);

                // send app invitation to new participants if in immersive mode
                if (["inImmersive"].includes(this.state.runningContext)) {
                    await zoomSdk.sendAppInvitation({ participantUUIDs: participantIds });
                }

                // update the participantIds state with the new participants
                this.setState({ participantIds });
            }

            if (participantsLeft.length) {
                logger.info(`[ZoomApp] onParticipantChange() participants left`, participantsLeft);

                // get full list of participant ids
                const participantIds = this.state.participantIds.filter(participantId => !participantsLeft.map(({ participantUUID }) => participantUUID).includes(participantId));

                // update the participantIds state with the new participants
                this.setState({ participantIds });
            }
        });
    };

    addRemoteControlListeners = async (runningContext: RunningContext) => {
        // get remote-control channel for the meeting room
        const roomID = window.roomID;
        this.remoteChannel = await pusher.subscribe(`presence-remote-control-${roomID}`);

        // both presenter and followers open to new app session in immersive mode
        if (runningContext === "inImmersive") {
            // trigger event about opening immersive mode
            logger.info("[ZoomApp] presence-remote-control client-immersive-mode-open event triggerred", { roomID });
            this.remoteChannel.trigger("client-immersive-mode-open", { roomID });

            // listen for presentation & leader ids to load the immersive mode
            this.remoteChannel.bind("client-immersive-mode-presentation", async ({ presentationId, presentationLinkId, leaderParticipantId }) => {
                logger.info("[ZoomApp] presence-remote-control client-immersive-mode-presentation event received");

                // set window remoteRole & trigger event to register as follower if not leader
                window.remoteRole = leaderParticipantId === this.state.participantId ? "leader" : "follower";
                if (window.remoteRole !== "leader") {
                    logger.info("[ZoomApp] presence-remote-control client-registerFollower event triggerred", { roomID });
                    this.remoteChannel.trigger("client-registerFollower", { roomID });

                    logger.info("[ZoomApp] record participant join event to server", { roomID, participantId: this.state.participantId });
                    this.recordMeetingParticipant(this.state.participantId, "join");

                    this.setState({ leaderParticipantId });
                } else {
                    this.setState({ presentationId, presentationLinkId, leaderParticipantId });
                }
            });

            // listen for camera overlay position change
            this.remoteChannel.bind("client-camera-overlay-position", ({ position }) => {
                logger.info("[ZoomApp] presence-remote-control client-camera-overlay-position event received", { position });

                this.setState({ immersiveCameraOverlayPosition: position }, () => this.drawPresenterCamera());
            });

            // listen to pusher events for presentation start
            this.remoteChannel.bind("client-registerLeader", ({ presentationId, presentationLinkId, startSlideIndex }) => {
                logger.info("[ZoomApp] presence-remote-control client-registerLeader event received", { presentationId, presentationLinkId, startSlideIndex });
                this.setState({ presentationId, presentationLinkId, startSlideIndex });
            });

            // listen to pusher events for presentation end
            this.remoteChannel.bind("client-endPresentation", ({ presentationId }) => {
                logger.info("[ZoomApp] presence-remote-control client-endPresentation event received", { presentationId });
                this.closeImmersiveMode();
            });

            // check for the zoom meeting presentation status
            setTimeout(() => {
                this.checkMeetingPresentation()
                    .then(({ presentationId }) => {
                        if (!presentationId) {
                            logger.info(`[ZoomApp] presentation already ended, closing immersive mode & bringing sidebar to front`, { roomID });
                            ShowErrorDialog({
                                title: "Error",
                                message: "This presentation has ended. Click close to navigate back to the sidebar.",
                                acceptOnClose: true,
                                acceptButtonText: "Close",
                                acceptCallback: () => {
                                    zoomSdk.closeRenderingContext();
                                    zoomSdk.bringAppToFront();
                                }
                            });
                        }
                    });
            }, 1000);
        }

        // in meeting app instance loaded to the sidebar
        if (runningContext === "inMeeting") {
            // listen for immersive mode finish loading
            this.remoteChannel.bind("client-immersive-mode-open", async ({ roomID }) => {
                logger.info("[ZoomApp] presence-remote-control client-immersive-mode-open event received", { roomID, remoteRole: window.remoteRole, isHost: this.appContext.attendrole === "host" });

                // trigger event to send presentation data to immersive apps
                if (window.remoteRole === "leader") {
                    logger.info("[ZoomApp] presence-remote-control client-immersive-mode-presentation event triggerred", { roomID });

                    if (!this.state.presentationId) {
                        logger.info(`[ZoomApp] presentation already ended, closing immersive mode`, { roomID });
                        await zoomSdk.closeRenderingContext();
                    } else {
                        logger.info(`[ZoomApp] presentation still runing, sending presentation data to immersive mode, and showing presenter view`, { roomID });
                        this.remoteChannel.trigger("client-immersive-mode-presentation", {
                            presentationId: this.state.presentationId,
                            presentationLinkId: this.state.presentationLinkId,
                            leaderParticipantId: this.state.participantId
                        });
                        this.setState({ showPresenterView: true });
                    }
                }

                // close the sidepanel view if not leader
                if (window.remoteRole !== "leader") {
                    logger.info("[ZoomApp] presentation follower in immersive mode, closing the sidepanel view", { roomID });
                    zoomSdk.sendAppToBackground();
                }

                // trigger event to send all participant counts to presenter view (if host but not leader, hosts will pass it via props)
                if (this.appContext.attendrole === "host" && window.remoteRole !== "leader") {
                    logger.info("[ZoomApp] presence-remote-control client-immersive-participants-count event triggerred", { roomID, participantIds: this.state.participantIds });
                    this.remoteChannel.trigger("client-immersive-participant-count", { participantIds: this.state.participantIds });
                }
            });
        }
    };

    setupMeetingRoom = async (runningContext: RunningContext) => {
        // remove invalid pusher channel characters from meetingID
        const meetingID = serverContext.zoomContext.mid as string;
        const roomID = meetingID.replace(/[^a-zA-Z0-9_\-=@,.;]/g, '');
        logger.info("[ZoomApp] setupMeetingRoom()", { meetingID, roomID });

        // get the participant id from the user context
        const { participantUUID } = await zoomSdk.getUserContext();
        this.setState({ participantId: participantUUID });

        // get all the meeting participants 
        if (this.appContext.attendrole === "host") {
            const { participants } = await zoomSdk.getMeetingParticipants();
            logger.info("[ZoomApp] setupMeetingRoom() getMeetingParticipants", { participants });

            this.setState({ participantIds: participants.map(({ participantUUID }) => participantUUID) });
        }

        // set roomID and callback functions to the window object
        window.roomID = roomID;
        window.roomPresentationStarted = this.startPresenting;
        window.roomPresentationClosed = this.endPresenting;

        // add remote-control listeners for the meeting room id
        await this.addRemoteControlListeners(runningContext);
    };

    checkMeetingPresentation = async () => {
        try {
            const resp = await fetch(`${serverUrl}/zoom/meeting-context`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ meetingId: window.roomID, action: "check" })
            });

            const data = await resp.json();
            return data;
        } catch (err) {
            logger.error(err, "[ZoomApp] error checking meeting presentation");
        }
    };

    recordMeetingPresentation = async (presentationId: string, action: "start" | "end") => {
        try {
            await fetch(`${serverUrl}/zoom/meeting-context`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ meetingId: window.roomID, presentationId, action })
            });
        } catch (err) {
            logger.error(err, "[ZoomApp] error recording meeting presentation");
        }
    };

    recordMeetingParticipant = async (participantId: string, action: "join" | "leave") => {
        try {
            await fetch(`${serverUrl}/zoom/meeting-context`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ meetingId: window.roomID, participantId, action })
            });
        } catch (err) {
            logger.error(err, `[ZoomApp] error recording meeting participant: ${participantId}`);
        }
    }

    startPresenting = async (presentationId: string, skipConfirm: boolean = false) => {
        if (this.state.isStartingToPresent) return;
        this.setState({ isStartingToPresent: true });

        // show warning dialog when there is another presenter in the meeting
        if (window.remoteRole === "follower") {
            ShowErrorDialog({
                title: "Error starting presentation",
                message: "Another participant is already presenting in this meeting. Please wait for them to finish.",
            });

            this.setState({ isStartingToPresent: false });
            return;
        }

        try {
            // show confirmation dialog for the selected presentation to start presenting
            if (!skipConfirm && !(await ShowConfirmationDialog({
                title: "Start presenting",
                message: "Do you want to start presenting this presentation in the meeting?",
                okButtonLabel: "Start presenting"
            }))) {
                logger.info(`[ZoomApp] user denied starting presentation`, { presentationId });
                this.setState({ isStartingToPresent: false });
                return;
            }

            // load the presentation model
            const presentation = await getPresentation(presentationId, { permission: "read", autoSync: false });

            // show confirmation dialog to the set presentation public if not already
            if (!presentation.get("public") || presentation.get("secured")) {
                if (!(await ShowConfirmationDialog({
                    title: "Please set your presentation to public in order to share via Zoom.",
                    message: "Your privacy settings will revert after you end your presentation.",
                    okButtonLabel: "Set to public"
                }))) {
                    logger.info(`[ZoomApp] user denied making presentation public`, { presentationId });
                    this.setState({ isStartingToPresent: false });
                    return;
                }

                const privacySettingChanged = presentation.get("secured") ? PresentationPrivacyType.SECURED : PresentationPrivacyType.PRIVATE;
                this.setState({ privacySettingChanged });

                await presentation.setPrivacySetting(PresentationPrivacyType.PUBLIC);
            }

            // load the PresentationLinks collection if not already loaded
            if (!presentation.links) {
                await presentation.loadPresentationLinks();
            }

            // create a public presentation link to pass to the player
            const presentationLink = await presentation.links.createLink({
                type: "public",
                name: `Zoom Collaborate - ${presentation.get("name")}`
            });

            // set the presentation and presentation link ids to the state
            this.setState({ presentationId, presentationLinkId: presentationLink.get("id") });

            // set yourself as the leader in your meeting app's instance
            window.remoteRole = "leader";

            // open immersive mode and share it to all participants in the meeting
            logger.info("[ZoomApp] startPresenting() open immersive mode", { presentationId });
            await this.openImmersiveMode();

            // record the presentation start in the meeting to server
            this.recordMeetingPresentation(presentationId, "start");

            // set the state to show the thumbnails view in the meeting view
            this.setState({ isStartingToPresent: false, showPresenterView: true });
        } catch (err) {
            ShowErrorDialog({
                title: "Error starting presentation",
                message: err.message,
            });

            this.setState({ isStartingToPresent: false });
            window.remoteRole = null;
        }
    };

    endPresenting = async (presentationId: string) => {
        try {
            if (this.state.presentationId !== presentationId ||
                !["inMeeting", "inImmersive"].includes(this.state.runningContext)) return;

            if (this.state.privacySettingChanged) {
                // load the presentation model
                const presentation = await getPresentation(presentationId, { permission: "read", autoSync: false });

                // set the presentation back to the original privacy setting
                await presentation.setPrivacySetting(this.state.privacySettingChanged);
            }

            // trigger the remote-control channel event to notify about the presentation end
            this.remoteChannel.trigger("client-endPresentation", { presentationId: this.state.presentationId });

            // record the presentation end in the meeting to server
            this.recordMeetingPresentation(presentationId, "end");

            // reset the state to show the library in this app instance
            this.setState({
                leaderParticipantId: null,
                presentationId: null,
                presentationLinkId: null,
                startSlideIndex: 0,
                showPresenterView: false,
                privacySettingChanged: "",
            });
        } catch (err) {
            ShowErrorDialog({
                title: "Error ending presentation",
                message: err.message,
            });
        }
    };

    openImmersiveMode = async () => {
        const { renderingContext } = this.state;

        if (renderingContext) {
            await zoomSdk.closeRenderingContext();
        }

        await zoomSdk.runRenderingContext({
            view: "immersive",
            defaultCutout: "standard"
        });

        // sending app invitation need to be done after the rendering context is open
        zoomSdk.sendAppInvitationToAllParticipants()
            .catch(this.handleAppInvitationErrors);
    };

    closeImmersiveMode = async () => {
        await zoomSdk.closeRenderingContext();
        await zoomSdk.bringAppToFront();

        this.setState({ showPresenterView: false });
    };

    handleCanvasResize = () => {
        this.drawPresenterCamera();
    }

    drawPresenterCamera = async () => {
        const { leaderParticipantId, participantsDrawn, immersiveCameraOverlayPosition } = this.state;

        if (participantsDrawn.length) {
            for (const participantId of participantsDrawn) {
                await zoomSdk.clearParticipant({ participantUUID: participantId })
                    .catch(err => logger.error(err, "[ZoomApp] error clearing participant"));
            }
            this.setState({ participantsDrawn: [] });
        }

        if (immersiveCameraOverlayPosition === CameraOverlayPosition.OFF) {
            return;
        }

        const { width: renderTargetWidth, height: renderTargetHeight } = this.playerRef.current.getCanvasDimensions();
        logger.info("[ZoomApp] drawPresenterCamera()", { renderTargetWidth, renderTargetHeight });

        const width = renderTargetWidth * 2;
        const height = renderTargetHeight * 2;

        if (immersiveCameraOverlayPosition === CameraOverlayPosition.FULL_SCREEN) {
            await zoomSdk.drawParticipant({
                participantUUID: leaderParticipantId,
                x: 0,
                y: 0,
                width,
                height,
                zIndex: 1,
                cameraModeMirroring: false
            }).catch(err => logger.error(err, "[ZoomApp] error drawing participant"));

            this.setState({ participantsDrawn: [leaderParticipantId] });
            return;
        }

        const avatarWidth = width / 8;
        const avatarHeight = height / 6;

        const padding = 20;
        const x = [CameraOverlayPosition.TOP_LEFT, CameraOverlayPosition.BOTTOM_LEFT].includes(immersiveCameraOverlayPosition)
            ? padding : (width - padding - avatarWidth);
        const y = [CameraOverlayPosition.TOP_LEFT, CameraOverlayPosition.TOP_RIGHT].includes(immersiveCameraOverlayPosition)
            ? padding : (height - (padding * 3) - avatarHeight);

        await zoomSdk.drawParticipant({
            participantUUID: leaderParticipantId,
            x, y,
            width: avatarWidth,
            height: avatarHeight,
            zIndex: 1,
            cameraModeMirroring: false
        }).catch(err => logger.error(err, "[ZoomApp] error drawing participant"));

        this.setState({ participantsDrawn: [leaderParticipantId] });
    }

    handleAppInvitationErrors = (err: Error) => {
        if (err.message.includes("call has been denied by user")) {
            logger.info("[ZoomApp] user denied sending app invitation to all participants");
            zoomSdk.closeRenderingContext();
            this.endPresenting(this.state.presentationId);
        } else if (err.message.includes("took longer than 10000ms to respond")) {
            logger.info("[ZoomApp] user took too long to accept or deny the app invitation, retrying");
            zoomSdk.sendAppInvitationToAllParticipants()
                .catch(this.handleAppInvitationErrors);
        } else {
            logger.error(err, "[ZoomApp] error sending app invitation to all participants");
        }
    };

    handleSendAppInvitation = async (participantIds: string[] = []) => {
        if (participantIds.length) {
            await zoomSdk.sendAppInvitation({ participantUUIDs: participantIds });
        } else {
            await zoomSdk.sendAppInvitationToAllParticipants();
        }
    }

    handleEndedPresentation = async () => {
        logger.info(`[ZoomApp] presentation ended, closing the immersive mode & bringing sidebar to front`);
        this.closeImmersiveMode();
    }

    handleWorkspaceSwitch = (workspaceId: string) => {
        logger.info(`[ZoomApp] workspace switched to ${workspaceId}, reloading the library view with new workspaceId`);

        this.setState({ workspaceId });
    }

    handleUserLogout = async () => {
        logger.info(`[ZoomApp] user logged out, disconnecting their zoom connection and logging out`);

        await Api.unlinkZoomAuth.delete();
        await auth().signOut();
    }

    render() {
        const { firebaseUser } = this.props;
        const { loading, loadingError, runningContext, presentationId, presentationLinkId, startSlideIndex, showPresenterView, participantId, participantIds, workspaceId } = this.state;

        if (loading) {
            return <Spinner />;
        }

        if (loadingError) {
            return <div>Error loading Zoom App: {JSON.stringify(loadingError, null, 2)}</div>;
        }

        if (!firebaseUser && !["inMainClient", "inImmersive"].includes(runningContext)) {
            return <ZoomAppAuth />;
        }

        switch (runningContext) {
            case "inMainClient":
                return <ZoomAppMainClient />;
            case "inMeeting":
                return showPresenterView
                    ? <ZoomAppPresenter firebaseUser={firebaseUser} presentationId={presentationId} participantId={participantId} participantIds={participantIds} handleSendAppInvitation={this.handleSendAppInvitation} />
                    : <ZoomAppLibrary firebaseUser={firebaseUser} workspaceId={workspaceId} />;
            case "inImmersive":
                return <ZoomAppPlayer ref={this.playerRef} firebaseUser={firebaseUser} participantId={participantId} presentationOrLinkId={presentationLinkId} startSlideIndex={startSlideIndex} handleCanvasResize={this.handleCanvasResize} />;
        }

        return null;
    }
}

export default withFirebaseUser(ZoomApp);
