import React, { Component } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import sanitizeHtml from "sanitize-html";
import { Person as PersonIcon } from "@material-ui/icons";
import { tinycolor } from "js/vendor";

import { player as playerApi } from "apis/callables";
import { app } from "js/namespaces";
import { serverUrl } from "js/config";
import { auth } from "js/firebase/auth";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import { prepareToUnlockAudio } from "js/core/utilities/audioUtilities";
import { ds } from "js/core/models/dataService";
import { User } from "js/core/models/user";
import { Presentation } from "js/core/models/presentation";
import { Slide } from "js/core/models/slide";
import getUserProfile from "js/core/services/userProfiles";
import { IAuthUser } from "common/interfaces";
import { identify } from "js/analytics";
import Spinner from "js/react/components/Spinner";
import { FlexSpacer, Gap10, Gap20, Gap5 } from "js/react/components/Gap";
import { formatDuration } from "js/react/views/Player/helpers/formatDuration";
import { Icon } from "js/Components/Icon";
import { Popup, PopupContent, PopupPreview } from "js/Components/Popup";
import { MenuItem } from "js/Components/Menu";
import { Button } from "js/Components/Button";
import { Divider } from "js/Components/Divider";
import { themeColors } from "js/react/sharedStyles";
import { ThemeManager } from "js/core/themeManager";
import Thumbnail from "js/react/views/Player/Components/Thumbnail";

const logger = getLogger(LogGroup.ZOOM);

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    color: white;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
`;

const Label = styled.div`
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ccc;
    display: flex;
    align-items: center;
`;

const SlideNotes = styled.div`
    display: flex;
    flex-direction: column;
    background: #333;
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px 0px 10px 15px;
`;

const SlideNotesContainer = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    font-size: 15px;
    line-height: 1.25;
    overflow-y: scroll;
    white-space: pre-line;
    overflow-wrap: break-word;

    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.3);
        border: 8px solid transparent;
        background-clip: content-box;
    }
`;

const Timer = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
`;

const NextSlide = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    max-height: 250px;
`;

const ParticipantsBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 8px 10px 10px;
    background: ${themeColors.warning};
`;

const ControlBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    flex-grow: 0;
`;

const ThumbnailsScrolledContainer = styled.div`
    max-height: calc(100% - 124px);
    overflow-y: auto;

    ::-webkit-scrollbar { 
        display: none;
    }
`;

const ThumbnailsContainer = styled.div`
    padding: 2px;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    justify-items: center;
    margin-bottom: 40px;
`;

const ThumbnailEndPage = styled.div<{ hilited?: boolean }>`
    width: 100%;
    height: 200px;
    cursor: pointer;
    outline: ${props => props.hilited ? "2px solid #50bbe6" : "none"};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    line-height: 15px;

    //avatar
    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 5px;
    }

    // person icon
    svg {
        padding: 4px;
        background: white;
        fill: #333;
        border-radius: 50%;
        margin-right: 10px;
    }

    .divider {
        width: 1px;
        height: 25px;
        background: #888;
        margin: 0px 15px;
    }
`;

export enum CameraOverlayPosition {
    OFF = "OFF",
    TOP_LEFT = "TOP_LEFT",
    TOP_RIGHT = "TOP_RIGHT",
    BOTTOM_LEFT = "BOTTOM_LEFT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    FULL_SCREEN = "FULL_SCREEN"
}

const CAMERA_OVERLAY_POSITIONS: Array<{ position: CameraOverlayPosition, label: string }> = [
    { position: CameraOverlayPosition.OFF, label: "Off" },
    { position: CameraOverlayPosition.TOP_LEFT, label: "Top Left" },
    { position: CameraOverlayPosition.TOP_RIGHT, label: "Top Right" },
    { position: CameraOverlayPosition.BOTTOM_LEFT, label: "Bottom Left" },
    { position: CameraOverlayPosition.BOTTOM_RIGHT, label: "Bottom Right" },
    { position: CameraOverlayPosition.FULL_SCREEN, label: "Full Screen" }
];

interface ZoomAppPresenterProps {
    firebaseUser: firebase.User;
    presentationId: string;
    participantId: string;
    participantIds: string[];
    handleSendAppInvitation: (participants?: string[]) => void;
}

interface ZoomAppPresenterState {
    isInitialized: boolean;
    view: "current" | "grid";
    slideTimer: number;
    showEndPage: boolean;
    currentSlideIndex: number;
    currentSlidePlaybackStageIndex: number;
    presentation: typeof Presentation;
    slides: { [slideId: string]: typeof Slide };
    slidesMetadata: Record<string, any>;
    currentCameraOverlayPosition: CameraOverlayPosition;
    creator: IAuthUser;
    participantsJoined: Array<string>;
    allParticipants: Array<string>;
}

class ZoomAppPresenter extends Component<ZoomAppPresenterProps, ZoomAppPresenterState> {
    remoteChannel: ExtendedChannel | null = null;
    slideTimerInterval: ReturnType<typeof setInterval> | null = null;
    pollTimeout: ReturnType<typeof setTimeout> | null = null;

    state: ZoomAppPresenterState = {
        isInitialized: false,
        view: "current",
        slideTimer: 0,
        showEndPage: false,
        currentSlideIndex: 0,
        currentSlidePlaybackStageIndex: 0,
        presentation: null,
        slides: null,
        slidesMetadata: null,
        currentCameraOverlayPosition: CameraOverlayPosition.OFF,
        creator: null,
        participantsJoined: [],
        allParticipants: [],
    };

    componentDidMount() {
        if (this.props.presentationId) {
            this.load();
        }
    }

    componentDidUpdate(prevProps: Readonly<ZoomAppPresenterProps>, prevState: Readonly<ZoomAppPresenterState>) {
        if (this.props.presentationId !== prevProps.presentationId) {
            this.load();
        } else if (this.props.participantIds.length !== prevProps.participantIds.length) {
            this.setState({ allParticipants: this.props.participantIds });
        }
    }

    componentWillUnmount() {
        if (this.remoteChannel) {
            this.remoteChannel.unsubscribe();
        }

        if (this.pollTimeout) {
            clearTimeout(this.pollTimeout);
        }
    }

    async load() {
        try {
            const { firebaseUser, presentationId } = this.props;

            // initialize user if not already existing
            if (!app.user && firebaseUser) {
                app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });
                if (!ds.hasBeenSetup) {
                    ds.dummySetup();
                }
                await app.user.load();
                await identify(app.user.getAuthUser());
            }

            // get presentation data from the player context api
            const {
                presentation: presentationData,
                slidesMetadata
            } = await playerApi.getPlayerContext({ id: presentationId, isZoomApp: true });

            // create new Presentation model instance with the presentation data
            const presentation = new Presentation({ ...presentationData }, { disconnected: true });

            // set presentation as the current selection
            ds.selection.presentation = presentation;
            ds.selection.presentation.slidesMetadata = slidesMetadata;

            // create new Slide model instances with the slide ids from the presentation data 
            // (note: these slides are not loaded yet, they will be loaded when needed by the player view)
            const slides = presentation.getSips()
                .map(slideId => new Slide({ id: slideId }, { presentation, autoLoad: false, autoSync: false }))
                .reduce((slides, slide) => ({ ...slides, [slide.id]: slide }), {});
            await Promise.all(Object.values(slides).map((slide: typeof Slide) => slide.load()));

            // load the presentation theme
            if (!app.themeManager) app.themeManager = new ThemeManager();
            await app.themeManager.loadTheme(presentation);

            // create audio buffer for the presentation
            await prepareToUnlockAudio();

            // subscribe to remote-control channel to listen for leader player events
            this.remoteChannel = await pusher.subscribe(`presence-remote-control-${window.roomID}`);

            // listen for the player navigation from the immersive view
            this.remoteChannel.bind("client-immersive-thumbnails-go-to-slide", ({ slideIndex, shouldAnimate, playbackStageIndex }) => {
                logger.info("[ZoomAppPresenter] client-immersive-thumbnails-go-to-slide", { slideIndex, shouldAnimate, playbackStageIndex });

                // update the selected thumbnail to the given slide index
                this.setState({
                    currentSlideIndex: parseInt(slideIndex),
                    currentSlidePlaybackStageIndex: parseInt(playbackStageIndex),
                    showEndPage: false
                });
            });

            // listen for the player navigation to show the end page
            this.remoteChannel.bind("client-immersive-thumbnails-show-end-page", () => {
                logger.info("[ZoomAppPresenter] client-immersive-thumbnails-show-end-page");

                // show the end page
                this.setState({ showEndPage: true, view: "grid" });
            });

            // listen for the participant joining the immersive view
            this.remoteChannel.bind("client-immersive-participant-joined", ({ userId }) => {
                logger.info("[ZoomAppPresenter] client-immersive-participant-joined", { userId });

                // add the participant to the state participants list
                this.setState(({ participantsJoined }) => ({
                    participantsJoined: [...participantsJoined.filter(id => id !== userId), userId]
                }));
            });

            // listen for the participant leaving the immersive view
            this.remoteChannel.bind("client-immersive-participant-left", ({ userId }) => {
                logger.info("[ZoomAppPresenter] client-immersive-participant-left", { userId });

                // remove the participant from the state participants list
                this.setState(({ participantsJoined }) => ({
                    participantsJoined: participantsJoined.filter(id => id !== userId)
                }));
            });

            // listen for the participant count in the immersive view
            this.remoteChannel.bind("client-immersive-participant-count", ({ participantIds }) => {
                logger.info("[ZoomAppPresenter] client-immersive-participant-count", { participantIds });

                // update the participant count in the state
                this.setState({ allParticipants: participantIds });
            });

            // load the presentation owner user record
            const creator = await getUserProfile(presentation.get("userId"));

            // start polling for waiting participants to join the immersive view
            this.startWaitingParticipantsPoll();

            // set the state to initialized with the presentation, slides and slides metadata & starts slide timer
            const { participantId, participantIds } = this.props;
            this.setState({ isInitialized: true, presentation, slides, slidesMetadata, creator, participantsJoined: [participantId], allParticipants: participantIds ?? [] });
            this.startSlideTimer();
        } catch (err) {
            logger.error(err, "[ZoomAppPresenter] Error loading presenter");
        }
    }

    async startWaitingParticipantsPoll() {
        if (this.pollTimeout) {
            clearTimeout(this.pollTimeout);
        }

        const execOnTimeout = async () => {
            await this.refreshWaitingParticipants();
            this.pollTimeout = setTimeout(execOnTimeout, 3000);
        };

        this.pollTimeout = setTimeout(execOnTimeout, 3000);
    }

    refreshWaitingParticipants = async () => {
        try {
            const idToken = await auth().currentUser.getIdToken();

            const resp = await fetch(`${serverUrl}/zoom/meeting-context`, {
                method: "PUT",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${idToken}` },
                body: JSON.stringify({ meetingId: window.roomID, action: "participants" })
            });

            let { participants } = await resp.json();
            participants = [...new Set(participants)];
            if (!participants.includes(this.props.participantId)) {
                participants.push(this.props.participantId);
            }

            this.setState({ participantsJoined: participants });
        } catch (err) {
            logger.error(err, "[ZoomAppPresenter] refreshWaitingParticipants failed");
        }
    };

    toggleGrid = () => {
        if (this.state.showEndPage) return;
        this.setState({ view: this.state.view === "current" ? "grid" : "current" });
    }

    toggleCameraOverlay = (position: CameraOverlayPosition) => {
        if (position === this.state.currentCameraOverlayPosition) return;

        // notify the immersive view to update the camera overlay position
        this.remoteChannel.trigger("client-camera-overlay-position", { position });
        this.setState({ currentCameraOverlayPosition: position });
    }

    startSlideTimer = () => {
        this.setState({ slideTimer: 0 });
        if (this.slideTimerInterval) {
            clearInterval(this.slideTimerInterval);
        }
        this.slideTimerInterval = setInterval(() => {
            this.setState(({ slideTimer }) => ({ slideTimer: slideTimer + 1 }));
        }, 1000);
    }

    goToNextSlide = () => {
        const { currentSlideIndex, slides } = this.state;
        if (currentSlideIndex < slides.length - 1) {
            this.goToSlide(currentSlideIndex + 1, true, 0);
        } else {
            this.showEndPage();
        }
    }

    goToSlide = (slideIndex: number, shouldAnimate: boolean, playbackStageIndex: number) => {
        this.remoteChannel.trigger("client-thumbnails-go-to-slide", {
            type: "slide", slideIndex, shouldAnimate, playbackStageIndex
        });

        this.setState({
            view: "current",
            currentSlideIndex: slideIndex,
            currentSlidePlaybackStageIndex: playbackStageIndex,
            showEndPage: false
        });
    }

    showEndPage = () => {
        this.setState({ showEndPage: true, view: "grid" });
        this.remoteChannel.trigger("client-thumbnails-show-end-page", {});
    }

    endPresenting = () => {
        window.roomPresentationClosed(this.state.presentation.id);
    }

    shareApp = () => {
        const { allParticipants, participantsJoined } = this.state;
        this.props.handleSendAppInvitation(allParticipants.filter(id => !participantsJoined.includes(id)));
    }

    render() {
        const { isInitialized, view, slideTimer, presentation, slides, slidesMetadata, showEndPage, currentSlidePlaybackStageIndex, currentCameraOverlayPosition, creator, participantsJoined, allParticipants } = this.state;

        if (!isInitialized) {
            return <Container><Spinner /></Container>;
        }

        const skippedSlideIds = Object.entries(slidesMetadata).map(([slideId, { isSkipped }]) => isSkipped ? slideId : null).filter(slideId => !!slideId);
        const slideIds = presentation.getSips(skippedSlideIds);

        const currentSlideIndex = showEndPage ? slideIds.length - 1 : this.state.currentSlideIndex;
        const currentSlideId = slideIds[currentSlideIndex];
        const currentSlide = slides[currentSlideId];

        const slideNotes = sanitizeHtml(currentSlide.get("slide_notes") || "No notes", {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(["strike"])
        });

        const Thumbnails = []; const ThumbnailsNextSlide = [];
        presentation.getSips(skippedSlideIds)
            .forEach((slideId, slideIndex) => {
                const slide = slides[slideId];
                const playbackStagesCount = slide.getPlaybackStageCount();
                [...Array(playbackStagesCount)].forEach((_, playbackStageIndex) => {
                    Thumbnails.push(
                        <Thumbnail
                            key={`${slideId} -${playbackStageIndex} `}
                            shouldLoadThumbnail={true}
                            hilited={slideIndex === currentSlideIndex && playbackStageIndex === currentSlidePlaybackStageIndex}
                            presentation={presentation}
                            slide={slide}
                            slideMetadata={slidesMetadata[slideId]}
                            clickable={true}
                            playbackStageIndex={playbackStageIndex}
                            slideIndex={slideIndex}
                            onClick={event => {
                                event.stopPropagation();
                                this.goToSlide(slideIndex, true, playbackStageIndex);
                            }}
                        />
                    );
                    ThumbnailsNextSlide.push(
                        <Thumbnail
                            key={`${slideId} -${playbackStageIndex} `}
                            shouldLoadThumbnail={true}
                            hilited={slideIndex === currentSlideIndex && playbackStageIndex === currentSlidePlaybackStageIndex}
                            presentation={presentation}
                            slide={slide}
                            slideMetadata={slidesMetadata[slideId]}
                            clickable={true}
                            playbackStageIndex={playbackStageIndex}
                            slideIndex={slideIndex}
                            hideSlideIndex={true}
                            onClick={event => {
                                event.stopPropagation();
                                this.goToSlide(slideIndex, true, playbackStageIndex);
                            }}
                        />
                    );
                });
            });

        const endPage = (
            <ThumbnailEndPage onClick={this.showEndPage}>
                {creator?.photoURL && <img src={creator?.photoURL}></img>}
                {!creator?.photoURL && <PersonIcon />}
                <Label>{creator?.displayName}</Label>
                <div className="divider" />
                <Label>{presentation.get("name")}</Label>
            </ThumbnailEndPage>
        );
        ThumbnailsNextSlide.push(endPage);

        const waitingParticipants = allParticipants.length - participantsJoined.length;

        return (
            <Container>
                {waitingParticipants > 0 && (
                    <ParticipantsBar>
                        <Icon color="white" fill>group</Icon>
                        <Gap5 />
                        Waiting for {waitingParticipants} {waitingParticipants > 1 ? "people" : "person"} to join.
                        <Gap5 />
                        <Button backgroundColor={tinycolor(themeColors.warning).darken(10).toString()} height={30} onClick={this.shareApp}>
                            Reinvite
                        </Button>
                    </ParticipantsBar>
                )}

                <ControlBar>
                    Slide {currentSlideIndex + 1} of {slideIds.length}
                    <Gap20 /> {/* @ts-ignore */}
                    <Timer>
                        <Icon color="white" fill>timer</Icon>
                        <Gap5 />
                        {formatDuration(slideTimer)}
                    </Timer>
                    <FlexSpacer />
                    <Icon color={view == "grid" ? themeColors.ui_blue : "white"} fill onClick={this.toggleGrid} disabled={showEndPage}>grid_on</Icon>
                    <Gap10 />
                    <Popup>
                        <PopupPreview>
                            <Icon color="white" fill>video_camera_front</Icon>
                        </PopupPreview>
                        <PopupContent>
                            <MenuItem disabled>Camera Overlay Position</MenuItem>
                            <Divider />
                            {CAMERA_OVERLAY_POSITIONS.map(({ position, label }, index) => (
                                <MenuItem
                                    key={index}
                                    selected={position === currentCameraOverlayPosition}
                                    onClick={() => this.toggleCameraOverlay(position)}
                                >
                                    {label}
                                </MenuItem>
                            ))}
                        </PopupContent>
                    </Popup>
                </ControlBar>

                {view == "current" && !showEndPage && <>
                    <Gap20 />
                    <Label>Speaker Notes</Label>
                    <Gap10 />
                    <SlideNotes>
                        <SlideNotesContainer dangerouslySetInnerHTML={{ __html: slideNotes }} />
                    </SlideNotes>
                    <Gap20 />
                    <Label>Next Slide</Label>
                    <Gap10 />
                    <NextSlide onClick={this.goToNextSlide}>
                        {ThumbnailsNextSlide[currentSlideIndex + 1] ?? ThumbnailsNextSlide[slideIds.length - 1]}
                    </NextSlide>
                    <Gap20 />
                </>}

                {view == "grid" && <>
                    <Gap20 />
                    <Label>Slide Grid</Label>
                    <Gap10 />
                    <ThumbnailsScrolledContainer>
                        <ThumbnailsContainer>
                            {Thumbnails}
                        </ThumbnailsContainer>
                    </ThumbnailsScrolledContainer>
                </>}

                <Button fullWidth warning onClick={this.endPresenting}>End Presentation</Button>
            </Container >
        );
    }
}

export default ZoomAppPresenter;
