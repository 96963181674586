import React, { Component } from "react";
import firebase from "firebase/compat/app";

import getLogger, { LogGroup } from "js/core/logger";
import Spinner from "js/react/components/Spinner";
import { Authenticate } from "js/react/views/Auth/Authenticate";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import PresentationLibrary from "js/editor/PresentationLibrary/PresentationLibrary";
import AppController from "js/core/AppController";
import { PresentationFilterType } from "js/controllers/PresentationLibraryController";
import { LibraryView } from "common/constants";
import { ds } from "js/core/models/dataService";
import { SharedThemes } from "js/core/models/sharedTheme";
import { BuiltInThemes, UserThemes } from "js/core/models/theme";

const logger = getLogger(LogGroup.ZOOM);

interface ZoomAppLibraryProps {
    firebaseUser: firebase.User;
    workspaceId: string;
}

interface ZoomAppLibraryState {
    isInitialized: boolean;
}

class ZoomAppLibrary extends Component<ZoomAppLibraryProps, ZoomAppLibraryState> {
    state = {
        isInitialized: false,
    };

    async componentDidMount() {
        try {
            window.document.body.classList.add("is_mobile");

            ds.prepare({ SharedThemes, BuiltInThemes, UserThemes });

            await AppController.initialize(this.props.firebaseUser, this.props.workspaceId);

            this.setState({ isInitialized: true });
        } catch (err) {
            logger.error(err, "Error initializing the ZoomAppLibrary");
            ShowErrorDialog({
                title: "Error initializing Zoom App",
                message: err.message,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<ZoomAppLibraryProps>) {
        if (prevProps.workspaceId !== this.props.workspaceId) {
            AppController.switchWorkspace(this.props.workspaceId, false);
        }
    }

    render() {
        if (!this.state.isInitialized) {
            return <Spinner />;
        }

        return (
            <Authenticate defaultPage="signIn">
                <PresentationLibrary
                    viewType="grid"
                    view={LibraryView.LIBRARY}
                    workspaceId={AppController.workspaceId}
                    filter={{ type: PresentationFilterType.ALL_PRESENTATIONS }}
                />
            </Authenticate>
        );
    }
}

export default AppController.withState(ZoomAppLibrary);
