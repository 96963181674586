import React, { Component } from "react";
import styled from "styled-components";
import zoomSdk from "@zoom/appssdk";

import BeautifulLogo from "js/react/components/BeautifulLogo";
import { Gap } from "js/react/components/Gap";
import { Button } from "js/Components/Button";
import { serverUrl } from "js/config";
import { RemoveSplashScreen } from "js/core/SplashScreen";
import { themeColors } from "js/react/sharedStyles";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; 
    background: ${themeColors.darkGray};
    color: ${themeColors.gray};
`;

const LabelText = styled.span`
    font-size: 18px;
    font-weight: 600;
    font-family: Source Sans Pro;
    color: ${themeColors.gray};
    text-align: center;
`;

class ZoomAppMainClient extends Component {
    state = {
        isOpeningBrowser: false,
        error: null,
    };

    componentDidMount() {
        RemoveSplashScreen();
    }

    onOpenBrowser = () => {
        zoomSdk.openUrl({ url: `${serverUrl}` });
    };

    render() {
        return (
            <Container>
                <BeautifulLogo noLink darkOnLight={false} forceMarketing={false} />
                <Gap size={40} />
                <LabelText>
                    The Beautiful.ai Zoom app can only be used in meetings.{<br />} Click “Apps” in your meeting window and open Beautiful.ai to play your presentations.
                </LabelText>
                <Gap size={40} />
                <Button blue width={300} onClick={this.onOpenBrowser}>
                    Open Beautiful.ai in browser
                </Button>
            </Container>
        );
    }
}

export default ZoomAppMainClient;
