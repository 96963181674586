import React, { Component } from "react";
import firebase from "firebase/compat/app";
import _ from "lodash";

import { player as playerApi } from "apis/callables";
import { app } from "js/namespaces";
import { serverUrl } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { prepareToUnlockAudio } from "js/core/utilities/audioUtilities";
import { ThemeManager } from "js/core/themeManager";
import { ds } from "js/core/models/dataService";
import { User } from "js/core/models/user";
import { Presentation } from "js/core/models/presentation";
import { Slide } from "js/core/models/slide";
import { SharedThemes } from "js/core/models/sharedTheme";
import { BuiltInThemes, UserThemes } from "js/core/models/theme";
import { identify } from "js/analytics";
import Spinner from "js/react/components/Spinner";
import PlayerView from "js/react/views/Player/Components/PlayerView";
import { getAnalytics, AnalyticsContextType } from "js/react/views/Player/helpers/analytics";

const logger = getLogger(LogGroup.ZOOM);

interface ZoomAppPlayerProps {
    firebaseUser: firebase.User;
    presentationOrLinkId: string;
    participantId: string;
    startSlideIndex?: number;
    handleCanvasResize?: () => void;
    handleEndedPresentation?: () => void;
}

interface ZoomAppPlayerState {
    isInitialized: boolean;
    contextData: {
        presentation: typeof Presentation;
        startSlideIndex: number | null;
        slides: { [slideId: string]: typeof Slide };
        slidesMetadata: any;
        creator: Pick<firebase.User, "displayName" | "photoURL">;
        username: string;
        isLoggedIn: boolean;
        analytics: ReturnType<typeof getAnalytics>;
        isMobileOrTablet: boolean;
    } | null;
}

class ZoomAppPlayer extends Component<ZoomAppPlayerProps, ZoomAppPlayerState> {
    playerRef: React.RefObject<PlayerView> = React.createRef();
    pollTimeout: ReturnType<typeof setTimeout> | null = null;

    state = {
        isInitialized: false,
        contextData: null,
    };

    componentDidMount() {
        window.isPlayer = true;
        if (this.props.presentationOrLinkId) {
            this.load();
        }
    }

    componentDidUpdate(prevProps: Readonly<ZoomAppPlayerProps>, prevState: Readonly<ZoomAppPlayerState>) {
        if (this.props.presentationOrLinkId !== prevProps.presentationOrLinkId) {
            this.load();
        }
    }

    componentWillUnmount(): void {
        window.isPlayer = false;
        if (this.pollTimeout) {
            clearTimeout(this.pollTimeout);
        }
    }

    async load() {
        try {
            const { firebaseUser, presentationOrLinkId, startSlideIndex } = this.props;

            logger.info("[ZoomAppPlayer] load()", { presentationOrLinkId, startSlideIndex });

            // prepare the themes models
            ds.prepare({ SharedThemes, BuiltInThemes, UserThemes });

            // initialize user if not already existing
            const isLoggedIn = firebaseUser && !firebaseUser.isAnonymous;
            if (!app.user && firebaseUser) {
                app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });
                if (!ds.hasBeenSetup) {
                    ds.dummySetup();
                }
                await app.user.load();
                await identify(app.user.getAuthUser());
            } else if (!ds.hasBeenSetup) {
                ds.dummySetup();
            }

            // get username from firebase user or search params, otherwise consider anonymous
            let username: string;
            if (isLoggedIn) {
                username = firebaseUser?.email;
            } else {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if (params.viewer) {
                    username = params.viewer;
                }
            }

            // get presentation data from the player context api
            const {
                presentation: presentationData,
                slidesMetadata,
                creator
            } = await playerApi.getPlayerContext({ id: presentationOrLinkId, isZoomApp: true });

            // create new Presentation model instance with the presentation data
            const presentation = new Presentation({ ...presentationData }, { disconnected: true });

            // set presentation as the current selection
            ds.selection.presentation = presentation;
            ds.selection.presentation.slidesMetadata = slidesMetadata;

            // create new Slide model instances with the slide ids from the presentation data 
            // (note: these slides are not loaded yet, they will be loaded when needed by the player view)
            const slides = presentation.getSips()
                .map(slideId => new Slide({ id: slideId }, { presentation, autoLoad: false, autoSync: false }))
                .reduce((slides, slide) => ({ ...slides, [slide.id]: slide }), {});

            // load the presentation theme
            if (!app.themeManager) app.themeManager = new ThemeManager();
            await app.themeManager.loadTheme(presentation);

            // create audio buffer for the presentation
            await prepareToUnlockAudio();

            // get the player analytics
            const analytics = getAnalytics(AnalyticsContextType.APP, false, firebaseUser, false, presentation);

            // build up the context data that will be passed to PlayerView
            const contextData: ZoomAppPlayerState["contextData"] = {
                presentation,
                startSlideIndex,
                slides,
                slidesMetadata,
                creator,
                username,
                isLoggedIn,
                analytics,
                isMobileOrTablet: app.isMobileOrTablet,
            };

            // start pinging the meeting context server to store your presence in the meeting
            this.startPingMeetingContextPoll();

            this.setState({ isInitialized: true, contextData });
        } catch (err) {
            logger.error(err, "[ZoomAppPlayer] Error loading player");
        }
    }

    async startPingMeetingContextPoll() {
        if (this.pollTimeout) {
            clearTimeout(this.pollTimeout);
        }

        const execOnTimeout = async () => {
            await this.pingMeetingContext();
            this.pollTimeout = setTimeout(execOnTimeout, 3000);
        };

        this.pollTimeout = setTimeout(execOnTimeout, 3000);
    }

    pingMeetingContext = async () => {
        try {
            const resp = await fetch(`${serverUrl}/zoom/meeting-context`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ meetingId: window.roomID, participantId: this.props.participantId, action: "ping" })
            });

            const { presentationEnded } = await resp.json();
            if (presentationEnded) {
                if (this.props.handleEndedPresentation) {
                    this.props.handleEndedPresentation();
                }
            }
        } catch (err) {
            logger.error(err, "[ZoomAppPlayer] Error pinging meeting context");
        }
    }

    getCanvasDimensions() {
        return this.playerRef.current?.canvasContainerWrapperRef.current.getBoundingClientRect();
    }

    handleCanvasResize = () => {
        this.props.handleCanvasResize && this.props.handleCanvasResize();
    }

    render() {
        const { isInitialized, contextData } = this.state;

        if (!isInitialized) {
            return <Spinner />;
        }

        return <PlayerView ref={this.playerRef} {...contextData} handleCanvasResize={this.handleCanvasResize} />;
    }
}

export default ZoomAppPlayer;
