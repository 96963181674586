import React, { Component } from "react";
import styled from "styled-components";
import zoomSdk from "@zoom/appssdk";

import BeautifulLogo from "js/react/components/BeautifulLogo";
import { Gap } from "js/react/components/Gap";
import { Button } from "js/Components/Button";
import getLogger, { LogGroup } from "js/core/logger";
import { serverUrl } from "js/config";
import { RemoveSplashScreen } from "js/core/SplashScreen";
import { themeColors } from "js/react/sharedStyles";

const logger = getLogger(LogGroup.ZOOM);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; 
    background: ${themeColors.darkGray};
    color: ${themeColors.gray};
`;

const LabelText = styled.span`
    font-size: 18px;
    font-weight: 600;
    font-family: Source Sans Pro;
    color: ${themeColors.gray};
    text-align: center;
`;

const ErrorText = styled.span`
    font-size: 24px;
    font-weight: 600;
    font-family: Source Sans Pro;
    color: ${themeColors.error};
    text-align: center;
`;

interface ZoomAppAuthProps {
    children?: React.ReactNode;
}

interface ZoomAppAuthState {
    isConnecting: boolean;
    error: string | null;
}

class ZoomAppAuth extends Component<ZoomAppAuthProps, ZoomAppAuthState> {
    state = {
        isConnecting: false,
        error: null,
    };

    componentDidMount() {
        RemoveSplashScreen();
    }

    onConnect = async () => {
        if (this.state.isConnecting) return;
        this.setState({ isConnecting: true });

        try {
            await zoomSdk.openUrl({ url: `${serverUrl}/zoom/login` });
            this.setState({ isConnecting: false });
        } catch (err) {
            logger.error(err, "[ZoomAppAuth] onConnect() failed");
            this.setState({ isConnecting: false, error: err.mesage });
        }
    };

    render() {
        const { isConnecting, error } = this.state;

        return (
            <Container>
                <BeautifulLogo noLink darkOnLight={false} forceMarketing={false} />
                <Gap size={40} />
                <LabelText>
                    Connect your account to share {<br />} your Beautiful.ai presentations in Zoom Meetings.
                </LabelText>
                <Gap size={40} />
                {error && <ErrorText>{error}</ErrorText>}
                <Button blue width={300} onClick={this.onConnect} disabled={isConnecting}>
                    {isConnecting ? "Opening browser to sign in" : "Connect"} to Beautiful.ai
                </Button>
            </Container>
        );
    }
}

export default ZoomAppAuth;
